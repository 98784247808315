<template>
	<div class="app-header">
		<app-section>
			<img class="logo" src="/images/logo.png">
			<div class="nav">
				<router-link :class="{ item: true, active: $route.path == item.href }" v-for="(item, i) in _menu" :key="i" :to="item.href">{{ item.label }}</router-link>
			</div>
		</app-section>
	</div>
</template>

<script>
	import AppSection from "@/components/AppSection.vue";

	export default {
		components: {
			AppSection
		},
		data: () => {
			return {
				menu: []
			};
		},
		computed: {
			_menu() {
				return this.menu.map((val) => {
					const parts = val.url.toString().split('/');
					const last = parts[parts.length - 1] == '' ? parts[parts.length - 2] : parts[parts.length - 1];

					return {
						label:	val.title,
						href:	val.url == '/' ? '/' : last
					};
				});
			}
		},
		async created() {
			this.menu = (await this.axios.get('/menu.php')).data;
		}
	}
</script>

<style lang="scss" scoped>
	.app-header {
		box-sizing: border-box;
		background-color: white;
		height: 80px;
		border-bottom: 3px solid rgb(255, 255, 255);

		-webkit-box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.36); 
		box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.36);

		& .logo {
			margin-top: 10px;
			height: 60px;

		}

		& .nav {
			float: right;

			& > .item {
				font-size: 14px;
				display: block;
				float: left;
				line-height: 77px;
				padding: 0 20px 0 20px;
				text-transform: uppercase;
				color: rgb(129, 129, 129);

				&.active {
					color: black;
					font-weight: bold;
					border-bottom: 3px solid #5fd4ff;
				}
			}
		}
	}

	@media only screen and (max-width: 1200px) {
		.app-header {
			padding: 0 0 0 20px;
		}
	}

	@media only screen and (max-width: 500px) {
		.app-header {
			& .nav {
				& > .item {
					font-size: 12px;
				}
			}
		}
	}
</style>