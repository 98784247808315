<template>
    <div class="pano-viewer" ref="viewer" @click="onPanoClick($event)">
		<div v-for="(nav, i) in _navigation" :key="`hotspot-${i}`" class="nav" ref="hotspots" @click="show(nav.name)"></div>
	</div>
</template>

<script>
	import Marzipano from 'marzipano';

	export default {
		props: {
			scenes: {
				type: Object,
				required: true,
			},
			modelValue: {
				type: String,
			}
		},
		data() {
			return {
				viewer: null,
				scene_cache: {},
			};
		},
		watch: {
			modelValue(n, o) {
				if (n == o) return;
				this.show(n);
			}
		},
		computed: {
			_options() {
				return {
					
				};
			},
			_navigation() {
				var nav = [];

				for (const name in this.scenes) {
					const data = this.scenes[name];

					if ('navigation' in data) {
						for (const n of data.navigation) {
							console.log(n);
							nav.push(n);
						}
					}
				}

				return nav;
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.viewer = new Marzipano.Viewer(this.$refs.viewer, this._options);
				this.cache();
			});
		},
		methods: {
			async onPanoClick(event) {
				const view = this.scene_cache[this.modelValue]._view;

				console.log(view.screenToCoordinates({
					x: event.layerX, 
					y: event.layerY
				}));
			},
			async show(name) {
				if (name === null) return;

				console.log('switch to', name, this.scene_cache[name]);

				// Change scene
				this.scene_cache[name].switchTo({
					transitionDuration: 1000
				});

				// Show hotspots
				this.scene_cache[name].hotspotContainer().show();
			},
			async cache() {
				await this.$nextTick(async () => {
					// Cache scenes
					for (const name in this.scenes) {
						this.scene_cache[name] = this.load(name);
					}

					// Cache hotspots
					let i = 0;

					for (const name in this.scenes) {
						const data = this.scenes[name];

						if ('navigation' in data) {
							const scene = this.scene_cache[name];

							for (const n of data.navigation) {
								scene.hotspotContainer().createHotspot(this.$refs.hotspots[i++], { 
									yaw: n.yaw, 
									pitch: n.pitch 
								}, {
									perspective: {
										extraTransforms: 'translate(-16px, -16px)'
									}
								});
							}
						}
					}

					await this.show(this.modelValue);
				});
			},
			load(name) {
				const data 		= this.scenes[name];
				const scene		= data.scene;
				const geometry 	= new Marzipano.CubeGeometry(scene.levels);
				const source 	= Marzipano.ImageUrlSource.fromString(`scenes/${name}/{z}/{f}/{y}/{x}.jpg`, { cubeMapPreviewUrl: `scenes/${name}/preview.jpg` });
				const limiter 	= Marzipano.RectilinearView.limit.traditional(scene.faceSize, 100 * Math.PI / 180, 120 * Math.PI / 180);
				const view 		= new Marzipano.RectilinearView(scene.initialViewParameters, limiter);

				return this.viewer.createScene({
					source: 		source,
					geometry: 		geometry,
					view: 			view,
					pinFirstLevel: 	true
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
.pano-viewer {
    background-color: black;
    color: white;
}

.nav {
	width: 32px;
	height: 32px;
	background-image: url('/images/marker_image.png');
	cursor: pointer;
}
</style>