<template>
	<div class="app-section">
		<slot />
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style lang="scss" scoped>
	.app-section {
		width: 1200px;
		margin: auto;
		position: relative;
	}

	@media only screen and (max-width: 1200px) {
		.app-section {
			width: auto;
		}
	}
</style>