<template>
	<div :class="['carousel-card', size]">
		<div class="left">
			<div class="img" :style="_img_style">&nbsp;</div>
		</div>
		<div class="right">
			<div class="title">{{ title }}</div>
			<div class="subtitle">{{ subtitle }}</div>
			<div class="place">{{ place }}</div>
			<div class="desc" v-html="size == 'full' ? content : desc"></div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			img: {
				type: String,
				default: '/images/event.jpg'
			},
			title: {
				type: String,
				default: 'Title'
			},
			subtitle: {
				type: String,
				default: 'Subtitle'
			},
			place: {
				type: String,
				default: 'Subtitle'
			},
			desc: {
				type: String,
				default: 'Desc'
			},
			content: {
				type: String,
				default: 'Desc'
			},
			size: {
				type: String,
				required: true
			}
		},
		computed: {
			_img_style() {
				return {
					backgroundImage: `url('${ this.img }')`
				};
			}
		}
	}
</script>

<style lang="scss" scoped>
	.carousel-card {
		text-align: left;
		background-color: white;
		display: grid;
		grid-template-columns: 100px 200px;

		-webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.7); 
		box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.7);

		border-radius: 3px;
		overflow: hidden;

		height: 150px;

		&.full {
			grid-template-columns: 10px 390px;
			height: 400px;
		}

		& > .left {
			padding: 10px;

			& > .img {
				background: white no-repeat center center;
				background-size: cover;
				height: 130px;
			}
		}

		& > .right {
			padding: 10px 10px 10px 0;

			& > .title {
				overflow: hidden;
				white-space: nowrap;
				text-transform: uppercase;
				text-overflow: ellipsis;
			}

			& > .subtitle {
				font-size: 12px;
				color: gray;
				margin: 0 0 8px 0;
			}

			& > .place {
				font-size: 14px;
				color:#5fd4ff;
				margin: 0 0 8px 0;
			}

			& > .desc {
				font-size: 14px ! important;
				overflow-y: auto;
				padding-bottom: 10px;
				max-height: 340px;

				&::v-deep p {
					font-size: 14px ! important;
					margin-top: 10px;
					line-height: 20px;
				}
			}
		}
	}
</style>