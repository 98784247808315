<template>
	<div class="app-carousel">
		<carousel-card :size="i == modelValue ? 'full' : 'preview'" :class="{ active: i == modelValue }" v-for="(card, i) in cards" :key="i" v-bind="card" :style="style(card, i)" @click="goto(i)" />
	</div>
</template>

<script>
	import CarouselCard from "@/components/CarouselCard.vue";

	export default {
		components: {
			CarouselCard
		},
		props: {
			modelValue: {
				type: Number,
				required: true
			},
			cards: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				width: 1200
			};
		},
		watch: {
			modelValue() {
				console.log('modelValue', this.modelValue)
			}
		},
		methods: {
			cardWidth(w) {
				if (w <= 500) return 230;
				return 300;
			},
			style(card, idx) {
				const max = this.cards.length / 2.;

				var delta = idx - this.modelValue;
				if (delta < -max)	delta = delta + this.cards.length;
				if (delta > max) 	delta = delta - this.cards.length;

				//const disp = Math.sin((delta / Math.floor(max)) * (Math.PI / 2.)) * 350;
				var disp = delta * (this.cardWidth(this.width) + 20);
				if (delta < 0) disp -= 50;
				if (delta > 0) disp += 50;

				return {
					//transform: `translate(${ disp }px, 0) scale(${1 - (0.5 * (Math.abs(delta) / max))}, ${1 - (0.5 * (Math.abs(delta) / max))})`,
					transform: `translate(${ disp }px, 0)`,
					zIndex: (Math.round(max) - Math.abs(delta)),
					//opacity: ((Math.floor(max) - Math.abs(delta)) / Math.floor(max)) * 1.
				};
			},
			goto(idx) {
				this.$emit('update:modelValue', idx);
			},
			prev() {
				const idx = this.modelValue == 0 ? this.cards.length - 1 : this.modelValue - 1;
				this.$emit('update:modelValue', idx);
			},
			next() {
				const idx = this.modelValue == this.cards.length - 1 ? 0 : this.modelValue + 1;
				console.log('next', idx);
				this.$emit('update:modelValue', idx);
			},
			onResize() {
				this.width = window.innerWidth;
			}
		},
		mounted() {
			window.addEventListener('resize', this.onResize);
			
			this.$nextTick(() => {
				this.onResize();
			});
		},
		beforeUnmount() {
			window.removeEventListener('resize', this.onResize);
		}
	}
</script>

<style lang="scss" scoped>
	.app-carousel {
		position: relative;
		height: 500px;
		overflow: hidden;
		padding: 5px 0 5px 0;
		pointer-events: none ! important;

		& > .carousel-card {
			pointer-events: all;
			transition: ease-in-out all 250ms;
			position: absolute;
			left: 50%;
			bottom: 5px;
			cursor: pointer;

			margin-left: -150px;

			&.full {
				margin-left: -200px;
			}

			&.active {
				/*-webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.36); 
				box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.36);*/
			}
		}
	}

	@media only screen and (max-width: 500px) {
		.app-carousel {
			& > .carousel-card {
				&.full {
					grid-template-columns: 10px 250px;
					margin-left: -125px;
					height: 250px;
				}

				&.left {

				}

				&.right {

				}
			}
		}
	}
</style>