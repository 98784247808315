<template>
	<router-view />
	<app-header />
	<app-footer />
</template>

<script>
	import AppHeader from "@/components/AppHeader.vue";
	import AppFooter from "@/components/AppFooter.vue";

	export default {
		components: {
			AppHeader,
			AppFooter
		}
	}
</script>

<style lang="scss">
	html, body {
		height: 100%;
	}

	#app {
		position: relative;
		min-height: 100%;
	}

	* {
		margin: 0;
		padding: 0;
		font-family: 'Lato', sans-serif;
		font-size: 16px;
		text-decoration: none;
		color: black;
		box-sizing: border-box;
	}

	hr {
		margin: 30px 0 30px 0;
		border: none;
		color: rgb(231, 231, 231);
	}

	h2 {
		font-size: 22px;
	}

	h1, h2, h3 {
		margin: 0 0 10px 0;
	}

	p {
		margin-bottom: 10px;
		line-height: 24px;
	}

	a {
		color: #5fd4ff;
	}

	body {
		background-color: rgb(235, 235, 235);
	}

	.app-header {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}

	.app-footer {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
	}
</style>
