<template>
    <div class="home-view">
		<pano-viewer v-if="scene !== null" class="pano" :scenes="scenes" v-model="scene" />
        <google-map v-if="scene === null" class="map" v-bind="_map_attr">
			<Marker v-for="(loc, i) in _locations" :key="`location-${loc.ID}`" :options="{ position: { lat: parseFloat(loc.lng), lng: parseFloat(loc.lat) }, icon: '/images/marker_small.png', opacity: tab == 'locations' && i != tabIndex ? 0.2 : 1.0 }" @click="markerClick(loc, i)" />
			<Marker v-for="(s, i) in scenes" :key="`location-${s.name}`" :options="{ position: { lat: parseFloat(s.position.lat), lng: parseFloat(s.position.lng) }, icon: '/images/marker_image.png', anchor: 'CENTER', opacity: tab == 'locations' && i != tabIndex ? 0.2 : 1.0 }" @click="scene = s.scene.name" />
			<Polyline v-for="(route, i) in _routes" :key="`route-${route.ID}`" :path="route.route" @click="routeClick(route, i)" :options="{
				path: route.route,
				geodesic: true,
				strokeColor: route.color,
				strokeOpacity: tab == 'routes' && i != tabIndex ? 0 : 1.0,
				strokeWeight: 2,
			}" />
		</google-map>
		<div class="bottom">
			<app-carousel v-model="tabIndex" :cards="_cards" />
			<div class="tabs" v-show="scene === null">
				<div :class="{ tab: true, active: tab == 'events' }" @click="tab = 'events'">Begivenheder</div>
				<div :class="{ tab: true, active: tab == 'locations' }" @click="tab = 'locations'">Lokationer</div>
				<div :class="{ tab: true, active: tab == 'routes' }" @click="tab = 'routes'">Ruter</div>
				<div :class="{ tab: true, hidden: tab == null }" @click="tab = null">X</div>
			</div>
			<div class="tabs" v-show="scene !== null">
				<div :class="{ tab: true }" @click="scene = null">X</div>
			</div>
		</div>
    </div>
</template>

<script>
	import AppSection from "@/components/AppSection.vue";
	import AppCarousel from "@/components/AppCarousel.vue";
	import PanoViewer from "@/components/PanoViewer.vue";
	import { GoogleMap, Marker, Polyline } from "vue3-google-map";

	// Scenes
	import beach_air from "../../public/scenes/beach - air/data.json";
	import camping_air from "../../public/scenes/camping - air/data.json";
	import beach_ground from "../../public/scenes/beach - ground/data.json";
	import camping_ground from "../../public/scenes/camping - ground/data.json";
	import island_ground from "../../public/scenes/island - ground/data.json";

	export default {
		data: () => {
			return {
				locations: [],
				events: [],
				routes: [],

				scenes: {
					'beach - air': 		beach_air,
					'camping - air': 	camping_air,
					'beach - ground': 	beach_ground,
					'camping - ground': camping_ground,
					'island - ground': 	island_ground
				},
				scene: null,

				tabIndex: 0,
				tab: null,
				center: { 
					lat: 55.1205363, 
					lng: 9.4918076
				},

				active_loc: null
			};
		},
		watch: {
			tab() {
				this.tabIndex = 0;
			},
			tabIndex() {
				// Got place on map
				
			}
		},
		components: {
			AppSection, AppCarousel, GoogleMap, Marker, Polyline, PanoViewer
		},
		computed: {
			_cards() {
				if (this.tab == null)			return [];
				if (this.tab == 'events')		return this._events;
				if (this.tab == 'locations')	return this._locations.map((val) => {
					return {
						img: 		val.img,
						title: 		val.post_title,
						subtitle: 	val.address,
						place: 		'',
						desc: 		val.post_excerpt,
						content: 	val.content
					};
				});
				if (this.tab == 'routes')		return this._routes.map((val) => {
					return {
						img: 		val.img,
						title: 		val.post_title,
						subtitle: 	'',
						place: 		'',
						desc: 		val.post_excerpt,
						content: 	val.content
					};
				});
			},
			_map_attr() {
				return {
					apiKey:		'AIzaSyDVev-mxS92gHrrNsiHAaJb9Sx_LptZ4vc',
					center: 	this.center,
					zoom: 		14,
					styles:		require('../map_style.json')		
				};
			},
			_events() {
				return this.events.map((val) => {
					return {
						img: val.img,
						title: val.post_title,
						subtitle: val.time,
						desc: val.post_excerpt,
						content: val.content,
						place: val.place[0].post_title
					};
				});
			},
			_routes() {
				return this.tab == 'routes' ? this.routes : [];
			},
			_locations() {
				return this.locations;
			}
		},
		methods: {
			goto(idx) {

			},
			markerClick(loc, idx) {
				this.tab = 'locations';

				this.$nextTick(() => {
					this.tabIndex = idx;
				});
			},
			routeClick(route, idx) {
				this.tab = 'routes';

				this.$nextTick(() => {
					this.tabIndex = idx;
				});
			}
		},
		async created() {
			this.events 	= (await this.axios.get('/events.php')).data;
			this.locations 	= (await this.axios.get('/locations.php')).data;
			this.routes 	= (await this.axios.get('/routes.php')).data;
		}
	};
</script>

<style lang="scss" scoped>
	.home-view {
		position: absolute;
		left: 0;
		top: 80px;
		width: 100%;
		bottom: 80px;

		& > .map, & > .pano {
			width: 100%; 
			height: 100%;
		}

		& > .bottom {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 0 0 20px 0;
			text-align: center;
			pointer-events: none;

			& .tabs {
				pointer-events: all;
				display: inline-block;
				background-color: white;
				height: 50px;

				-webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.7); 
				box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.7);

				border-radius: 3px;
				overflow: hidden;

				& > .tab {
					transition: ease-in-out all 250ms;
					cursor: pointer;
					line-height: 47px;
					padding: 0 20px 0 20px;
					display: inline-block;
					text-transform: uppercase;
					font-size: 12px;
					border-bottom: 3px solid white;
					overflow: hidden;

					&.active {
						border-bottom: 3px solid #5fd4ff;
						font-weight: bold;
					}

					&.hidden {
						width: 0px;
						padding: 0px 0px 0px 0px;
					}
				}
			}

			& .app-carousel {
				pointer-events: all;
				width: 100%;
				margin-bottom: 20px;
			}
		}
	}
</style>