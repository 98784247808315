<template>
	<div class="app-footer">
		<app-section>
			<a id="footer-agri" href="https://ec.europa.eu/info/food-farming-fisheries/key-policies/common-agricultural-policy/rural-development_da" target="_blank"><img src="/images/agri_logo.png"></a>
			<a id="footer-gov" href="https://erhvervsstyrelsen.dk/" target="_blank" style="margin-left: 20px;"><img src="/images/bizz_logo.png"></a>
		</app-section>
	</div>
</template>

<script>
	import AppSection from "@/components/AppSection.vue";

	export default {
		components: {
			AppSection
		}
	}
</script>

<style lang="scss" scoped>
	.app-footer {
		width: 100%;
		height: 80px;
		background-color: white;
		padding-top: 15px;
	}

	@media only screen and (max-width: 1200px) {
		.app-footer {
			padding: 15px 20px 0 20px;
		}
	}

	@media only screen and (max-width: 550px) {
		.app-footer {
			#footer-agri > img {
				height: 40px;
			}

			#footer-gov > img {
				height: 40px;
			}
		}
	}

	@media only screen and (max-width: 450px) {
		.app-footer {
			#footer-agri > img {
				height: 30px;
			}

			#footer-gov > img {
				height: 30px;
			}
		}
	}
</style>